// Consider updating _UserMenuActiveProfile.scss when changing the value
$userMenu-padding-x: map-get($spacers, 3);



header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;

  display: flex;
  height: $header-height;

  border-bottom: $border-width solid $border-color;
  background-color: $header-bg;

  @include media-breakpoint-up(md) {
    padding-left: $sidebar-width;
  }

  .header-divider {
    height: auto;
    margin: $spacer;
    border-left: $border-width solid $border-color;

    & + .header-divider {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .sidebar-toggle {
      display: none;

      & + .header-divider {
        display: none;
      }
    }

    .breadcrumb {
      margin-left: subtract($content-padding-x, $breadcrumb-padding-x);
    }

    .user-menu {
      margin-right: subtract($content-padding-x, $userMenu-padding-x);
    }
  }

  .breadcrumb {
    font-family: $title-medium-font-family;
    font-weight: $title-medium-font-weight;
    font-size: $title-medium-font-size;

    align-items: center;

    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .breadcrumb-item {
    a {
      color: inherit;
    }
  }
}
