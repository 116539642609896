// ############################################################################
// Pre-import variable overrides
// ############################################################################

// Color system

$white:                     #fff;
$gray-100:                  $palette-black-100;
$gray-200:                  $palette-black-200;
$gray-300:                  $palette-black-300;
$gray-400:                  $palette-black-400;
$gray-500:                  $palette-black-500;
$gray-600:                  $palette-black-600;
$gray-700:                  $palette-black-700;
$gray-800:                  $palette-black-800;
$gray-900:                  $palette-black-900;
$black:                     #000;

$blue:                      $color-info;
$green:                     $color-success;
$orange:                    $color-warning;
$red:                       $color-error;

$primary:                   $color-primary;
$secondary:                 $color-secondary;
$success:                   $color-success;
$info:                      $color-info;
$warning:                   $color-warning;
$danger:                    $color-error;
$light:                     $palette-black-100;
$dark:                      $palette-black-1000;

$primary-bg:                $color-primary-bg;
$disabled:                  $palette-black-400;
$disabled-bg:               $palette-black-200;

$theme-color-interval:      5%;
$yiq-contrasted-threshold:  190;



// Options

$enable-shadows:            true;
$enable-validation-icons:   false;



// Spacing

$spacer:  .75rem;
$spacers: (
  0: 0,
  1: $spacer - (.125rem * 5),
  2: $spacer - (.125rem * 4),
  3: $spacer - (.125rem * 2),
  4: $spacer,
  5: $spacer + (.25rem * 1),
  6: $spacer + (.25rem * 2),
  7: $spacer + (.25rem * 4),
  8: $spacer + (.25rem * 6)
);



// Body

$body-bg:             $white;
$body-color:          $palette-black-1000;

$header-height:       3.5rem;
$header-bg:           $neutral-surface;

$footer-height:       5.25rem;
$footer-padding-top:  3rem;

$sidebar-width:       6rem;
$sidebar-logo-size:   2rem;

$content-padding-y:   .75rem;
$content-padding-x:   2.5rem;



// Grid breakpoints

$grid-breakpoints: (
  xs:   0,
  sm:   768px,
  md:   960px,
  lg:   1152px,
  xl:   1344px
);



// Components

$border-color:          $palette-purple-200;
$border-color-alt:      $palette-blue-100;

// Deprecated variables.
// New variables can be found in _constants.scss
$border-radius:         0;
$border-radius-lg:      0;
$border-radius-sm:      0;

$caret-width:           .25em;
$caret-vertical-align:  middle;
$caret-spacing:         $caret-width;

$scrollbar-size:        .25rem;
$scrollbar-color:       $palette-blue-200;
$scrollbar-bg:          $palette-blue-50;



// Typography

$font-family-base:                    $body-large-font-family;
$font-weight-base:                    $body-large-font-weight;

$font-size-base:                      $body-large-font-size;
$font-size-sm:                        $body-medium-font-size;
$font-size-lg:                        1rem; // TODO

$h1-font-size:                        $font-size-base + (.25rem * 6);
$h2-font-size:                        $font-size-base + (.25rem * 4);
$h3-font-size:                        $font-size-base + (.25rem * 3);
$h4-font-size:                        $font-size-base + (.25rem * 2);
$h5-font-size:                        $font-size-base + (.25rem * 1);
$h6-font-size:                        $font-size-base + (.25rem * 0);

$headings-font-family:                $heading-medium-font-family;
$headings-font-weight:                $heading-medium-font-weight;

$display1-size:                       6rem;
$display2-size:                       5rem;
$display3-size:                       4rem;
$display4-size:                       3rem;

$blockquote-font-size:                $font-size-base + .25rem;
$lead-font-size:                      $font-size-base + .25rem;
$small-font-size:                     $font-size-base - .25rem;

$text-muted:                          $palette-black-600;

$hr-border-color:                     $border-color;


// Buttons + Forms

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         .625rem;
$input-btn-font-size:         .75rem;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      .625rem;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      .75rem;
$input-btn-font-size-lg:      .875rem;

$input-btn-focus-width:       .125rem;



// Buttons

$btn-font-family:           $label-large-font-family;
$btn-font-weight:           $label-large-font-weight;

$btn-font-size:             $label-large-font-size;
$btn-line-height:           $icon-size-md;
$btn-icon-size:             $icon-size-md;

$btn-font-size-sm:          $label-small-font-size;
$btn-line-height-sm:        $icon-size-sm;
$btn-icon-size-sm:          $icon-size-sm;

$btn-font-size-lg:          $label-large-font-size;
$btn-line-height-lg:        $icon-size-lg;
$btn-icon-size-lg:          $icon-size-lg;

$btn-padding-y:             .375rem;
$btn-padding-x:             .75rem;
$btn-border-radius:         .25rem;
$btn-icon-text-gap:         .25rem;

$btn-padding-y-sm:          .125rem;
$btn-padding-x-sm:          .25rem;
$btn-border-radius-sm:      .125rem;
$btn-icon-text-gap-sm:      .125rem;

$btn-padding-y-lg:          .3125rem;
$btn-padding-x-lg:          .75rem;
$btn-border-radius-lg:      .25rem;
$btn-icon-text-gap-lg:      .25rem;

$btn-loader-width:          .125rem;

$btn-box-shadow:            none;
$btn-focus-box-shadow:      none;
$btn-active-box-shadow:     none;

$btn-disabled-opacity:      .5;
$btn-link-disabled-color:   $disabled;



// Forms

$input-disabled-bg:                         $disabled-bg;
$input-group-addon-bg:                      $disabled-bg;
$input-placeholder-color:                   $disabled;

$input-color:                               $palette-black-1000;
$input-border-color:                        $border-color;
$input-box-shadow:                          none;

$form-label-width:                          10rem;
$form-group-margin-bottom:                  .75rem;

$custom-control-gutter:                     0;

$custom-control-indicator-border-color:     $secondary;
$custom-control-label-disabled-color:       $disabled;

$custom-checkbox-indicator-border-radius:   50%;



// Cards

$card-spacer-y:                     .375rem;
$card-spacer-x:                     .75rem;

$card-border-color:                 $border-color;
$card-border-radius:                .5rem;

$card-bg:                           $neutral-surface;
$card-cap-bg:                       $neutral-surface;



// Navs

$nav-link-padding-y:                .25rem;
$nav-link-padding-x:                .75rem;
$nav-link-disabled-color:           $disabled;

$nav-tabs-border-color:             $border-color;
$nav-tabs-border-width:             2px;

$nav-tabs-link-active-color:        inherit;
$nav-tabs-link-active-bg:           inherit;

$nav-tabs-link-hover-border-color:  $secondary;
$nav-tabs-link-active-border-color: $secondary;



// Breadcrumbs

$breadcrumb-padding-y:      .375rem;
$breadcrumb-padding-x:      .75rem;
$breadcrumb-item-padding:   .375rem;

$breadcrumb-margin-bottom:  0;

$breadcrumb-bg:             none;
$breadcrumb-active-color:   inherit;

$breadcrumb-divider:        quote("›");
$breadcrumb-divider-color:  inherit;



// Spinners

$spinner-width:             1.75rem;
$spinner-width-sm:          .875rem;

$spinner-border-width:      .25rem;
$spinner-border-width-sm:   .125rem;



// Badges

$badge-padding-y:                   1px;
$badge-padding-x:                   .5rem;
$badge-border-radius:               .25rem;
$badge-gap:                         .25rem;



// Modals

$modal-inner-padding:               0;
$modal-footer-margin-between:       0;

$modal-dialog-margin:               .5rem;
$modal-dialog-margin-y-sm-up:       1.5rem;

$modal-title-line-height:           null;

$modal-content-bg:                  $neutral-surface;
$modal-content-border-color:        transparent;
$modal-content-border-width:        0;
$modal-content-border-radius:       .5rem;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs:       $elevation-200-bottom;
$modal-content-box-shadow-sm-up:    $elevation-200-bottom;

$modal-backdrop-bg:                 $secondary;
$modal-backdrop-opacity:            .16;

$modal-header-padding-y:            0;
$modal-header-padding-x:            0;



// Offcanvas

$offcanvas-width:                   30rem;
$offcanvas-header-height:           4rem;
$offcanvas-border-radius:           .5rem;

$offcanvas-line-padding:            2.25rem;
$offcanvas-close-padding:           .125rem;

$offcanvas-padding-y:               1rem;
$offcanvas-padding-x:               1.5rem;



// List group

$list-group-color:                  $body-color;
$list-group-bg:                     $neutral-surface;
$list-group-border-color:           $border-color;

$list-group-item-padding-y:         .5rem;
$list-group-item-padding-x:         1rem;

$list-group-hover-bg:               $palette-blue-50;
$list-group-active-color:           $list-group-color;
$list-group-active-bg:              $palette-blue-200;
$list-group-active-border-color:    $list-group-border-color;

$list-group-disabled-color:         $disabled;
$list-group-disabled-bg:            $disabled-bg;

$list-group-action-color:           $list-group-color;
$list-group-action-hover-color:     $list-group-color;

$list-group-action-active-color:    $list-group-active-color;
$list-group-action-active-bg:       $list-group-active-bg;



// ############################################################################
// Import required parts
// ############################################################################

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";



// ############################################################################
// Post-import variable overrides
// ############################################################################

// Dropdowns

$dropdown-padding-x:                0;
$dropdown-padding-y:                0;
$dropdown-spacer:                   0;
$dropdown-bg:                       $neutral-surface;
$dropdown-border-color:             $border-color;
$dropdown-inner-border-radius:      0;

$dropdown-divider-bg:               $border-color;
$dropdown-divider-margin-y:         .25rem;
$dropdown-box-shadow:               $elevation-600;

$dropdown-link-color:               $secondary;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            $primary-bg;

$dropdown-item-padding-y:           $btn-padding-y;
$dropdown-item-padding-x:           $btn-padding-x;

$dropdown-header-color:             $secondary;
$dropdown-header-padding:           $dropdown-item-padding-y $dropdown-item-padding-x;

$dropdown-menu-tip-size:            .5rem;



// Popovers

$popover-font-size:           $font-size-base;

$popover-max-width:           20rem;
$popover-border-color:        $border-color;
$popover-box-shadow:          $elevation-600;

$popover-body-padding-y:      0;
$popover-body-padding-x:      0;

$popover-arrow-outer-color:   $border-color;



// ############################################################################
// Import optional parts
// ############################################################################

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
// we are not using navbar
// @import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
// we are not using pagination
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// we are not using jumbotron
// @import "~bootstrap/scss/jumbotron";
// we are not using alert
// @import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// we are not using toasts
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// we are not using tooltips
// @import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
// we are not using carousel
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
