$rpvue-ActivityStreamCard-background:           $container-surface;
$rpvue-ActivityStreamCard-borderRadius:         .5rem;
$rpvue-ActivityStreamCard-content-padding:      .5rem .75rem;



.rpvue-ActivityStreamCard {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  background: $rpvue-ActivityStreamCard-background;
  border-radius: $rpvue-ActivityStreamCard-borderRadius;
}

.rpvue-ActivityStreamCard-body {
  display: flex;
  flex-direction: column;
}

.rpvue-ActivityStreamCard-content {
  padding: $rpvue-ActivityStreamCard-content-padding;
}
