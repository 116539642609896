$rpvue-ActivityStreamEmailContent-maxHeight:                    10rem;
$rpvue-ActivityStreamEmailContent-padding:                      .75rem;
$rpvue-ActivityStreamEmailContent-gap:                          .75rem;
$rpvue-ActivityStreamEmailContent-subject-color:                $neutral-surface-var-bg;
$rpvue-ActivityStreamEmailContent-subject-font:                 $label-medium;
$rpvue-ActivityStreamEmailContent-html-color:                   $neutral-on-bg;
$rpvue-ActivityStreamEmailContent-html-font:                    $label-large;
$rpvue-ActivityStreamEmailContent-overflow-padding:             1.5rem 0 .5rem;
$rpvue-ActivityStreamEmailContent-overflow-color:               $neutral-on-bg;
$rpvue-ActivityStreamEmailContent-overflow-background:          linear-gradient(180deg, #0000, $container-surface);
$rpvue-ActivityStreamEmailContent-overflowIcon-size:            1.5rem;
$rpvue-ActivityStreamEmailContent-overflowIcon-fontSize:        $icon-size-md;
$rpvue-ActivityStreamEmailContent-overflowIcon-border:          $border-width solid $border-color;
$rpvue-ActivityStreamEmailContent-overflowIcon-background:      $neutral-surface;



.rpvue-ActivityStreamEmailContent {
  display: flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  max-height: $rpvue-ActivityStreamEmailContent-maxHeight;

  padding: $rpvue-ActivityStreamEmailContent-padding;
  gap: $rpvue-ActivityStreamEmailContent-gap;

  &.is-expanded {
    max-height: none;
  }
}

.rpvue-ActivityStreamEmailContent-subject {
  color: $rpvue-ActivityStreamEmailContent-subject-color;

  @include font-settings($rpvue-ActivityStreamEmailContent-subject-font);
}

.rpvue-ActivityStreamEmailContent-html {
  word-break: break-word;

  color: $rpvue-ActivityStreamEmailContent-html-color;

  @include font-settings($rpvue-ActivityStreamEmailContent-html-font);
}

.rpvue-ActivityStreamEmailContent-overflow {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  padding: $rpvue-ActivityStreamEmailContent-overflow-padding;

  color: $rpvue-ActivityStreamEmailContent-overflow-color;
  background: $rpvue-ActivityStreamEmailContent-overflow-background;

  cursor: pointer;
}

.rpvue-ActivityStreamEmailContent-overflowIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: $rpvue-ActivityStreamEmailContent-overflowIcon-size;
  height: $rpvue-ActivityStreamEmailContent-overflowIcon-size;

  border: $rpvue-ActivityStreamEmailContent-overflowIcon-border;
  border-radius: divide($rpvue-ActivityStreamEmailContent-overflowIcon-size, 2);
  background: $rpvue-ActivityStreamEmailContent-overflowIcon-background;

  font-size: $rpvue-ActivityStreamEmailContent-overflowIcon-fontSize;
}
