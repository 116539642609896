// ############################################################################
// Typography
// ############################################################################

// Fonts

$font-family-axiforma:                "Axiforma";
$font-weight-axiforma-regular:        400;
$font-weight-axiforma-medium:         500;
$font-weight-axiforma-semibold:       600;
$font-weight-axiforma-bold:           700;

$font-family-proxima-nova:            "Proxima Nova";
$font-weight-proxima-nova-regular:    400;
$font-weight-proxima-nova-medium:     500;
$font-weight-proxima-nova-semibold:   600;
$font-weight-proxima-nova-bold:       700;

// Display

$display-large-font-family:           $font-family-axiforma;
$display-large-font-weight:           $font-weight-axiforma-bold;
$display-large-font-size:             3.25rem;
$display-large-line-height:           3.875rem;
$display-large-letter-spacing:        2px;

$display-large: (
  font-family:    $display-large-font-family,
  font-weight:    $display-large-font-weight,
  font-size:      $display-large-font-size,
  line-height:    $display-large-line-height,
  letter-spacing: $display-large-letter-spacing
);

$display-medium-font-family:          $font-family-axiforma;
$display-medium-font-weight:          $font-weight-axiforma-semibold;
$display-medium-font-size:            2.75rem;
$display-medium-line-height:          3.25rem;
$display-medium-letter-spacing:       0px;

$display-medium: (
  font-family:                        $display-medium-font-family,
  font-weight:                        $display-medium-font-weight,
  font-size:                          $display-medium-font-size,
  line-height:                        $display-medium-line-height,
  letter-spacing:                     $display-medium-letter-spacing
);

$display-small-font-family:           $font-family-axiforma;
$display-small-font-weight:           $font-weight-axiforma-semibold;
$display-small-font-size:             2.25rem;
$display-small-line-height:           2.75rem;
$display-small-letter-spacing:        0px;

$display-small: (
  font-family:                        $display-small-font-family,
  font-weight:                        $display-small-font-weight,
  font-size:                          $display-small-font-size,
  line-height:                        $display-small-line-height,
  letter-spacing:                     $display-small-letter-spacing
);

// Heading

$heading-large-font-family:           $font-family-axiforma;
$heading-large-font-weight:           $font-weight-axiforma-semibold;
$heading-large-font-size:             2rem;
$heading-large-line-height:           2.5rem;
$heading-large-letter-spacing:        0px;

$heading-large: (
  font-family:                        $heading-large-font-family,
  font-weight:                        $heading-large-font-weight,
  font-size:                          $heading-large-font-size,
  line-height:                        $heading-large-line-height,
  letter-spacing:                     $heading-large-letter-spacing
);

$heading-medium-font-family:          $font-family-axiforma;
$heading-medium-font-weight:          $font-weight-axiforma-semibold;
$heading-medium-font-size:            1.5rem;
$heading-medium-line-height:          2rem;
$heading-medium-letter-spacing:       0px;

$heading-medium: (
  font-family:                        $heading-medium-font-family,
  font-weight:                        $heading-medium-font-weight,
  font-size:                          $heading-medium-font-size,
  line-height:                        $heading-medium-line-height,
  letter-spacing:                     $heading-medium-letter-spacing
);

$heading-small-font-family:           $font-family-axiforma;
$heading-small-font-weight:           $font-weight-axiforma-medium;
$heading-small-font-size:             1.25rem;
$heading-small-line-height:           1.75rem;
$heading-small-letter-spacing:        0px;

$heading-small: (
  font-family:                        $heading-small-font-family,
  font-weight:                        $heading-small-font-weight,
  font-size:                          $heading-small-font-size,
  line-height:                        $heading-small-line-height,
  letter-spacing:                     $heading-small-letter-spacing
);

// Title

$title-large-font-family:             $font-family-axiforma;
$title-large-font-weight:             $font-weight-axiforma-semibold;
$title-large-font-size:               1.125rem;
$title-large-line-height:             1.5rem;
$title-large-letter-spacing:          0px;

$title-large: (
  font-family:                        $title-large-font-family,
  font-weight:                        $title-large-font-weight,
  font-size:                          $title-large-font-size,
  line-height:                        $title-large-line-height,
  letter-spacing:                     $title-large-letter-spacing
);

$title-medium-font-family:            $font-family-axiforma;
$title-medium-font-weight:            $font-weight-axiforma-medium;
$title-medium-font-size:              1rem;
$title-medium-line-height:            1.25rem;
$title-medium-letter-spacing:         0px;

$title-medium: (
  font-family:                        $title-medium-font-family,
  font-weight:                        $title-medium-font-weight,
  font-size:                          $title-medium-font-size,
  line-height:                        $title-medium-line-height,
  letter-spacing:                     $title-medium-letter-spacing
);

$title-small-font-family:             $font-family-proxima-nova;
$title-small-font-weight:             $font-weight-proxima-nova-medium;
$title-small-font-size:               .875rem;
$title-small-line-height:             1.125rem;
$title-small-letter-spacing:          0px;

$title-small: (
  font-family:                        $title-small-font-family,
  font-weight:                        $title-small-font-weight,
  font-size:                          $title-small-font-size,
  line-height:                        $title-small-line-height,
  letter-spacing:                     $title-small-letter-spacing
);

// Label

$label-large-font-family:             $font-family-proxima-nova;
$label-large-font-weight:             $font-weight-proxima-nova-medium;
$label-large-font-size:               .875rem;
$label-large-line-height:             1.125rem;
$label-large-letter-spacing:          0px;

$label-large: (
  font-family:                        $label-large-font-family,
  font-weight:                        $label-large-font-weight,
  font-size:                          $label-large-font-size,
  line-height:                        $label-large-line-height,
  letter-spacing:                     $label-large-letter-spacing
);

$label-medium-font-family:            $font-family-proxima-nova;
$label-medium-font-weight:            $font-weight-proxima-nova-medium;
$label-medium-font-size:              .75rem;
$label-medium-line-height:            1rem;
$label-medium-letter-spacing:         0px;

$label-medium: (
  font-family:                        $label-medium-font-family,
  font-weight:                        $label-medium-font-weight,
  font-size:                          $label-medium-font-size,
  line-height:                        $label-medium-line-height,
  letter-spacing:                     $label-medium-letter-spacing
);

$label-small-font-family:             $font-family-proxima-nova;
$label-small-font-weight:             $font-weight-proxima-nova-regular;
$label-small-font-size:               .625rem;
$label-small-line-height:             .875rem;
$label-small-letter-spacing:          0px;

$label-small: (
  font-family:                        $label-small-font-family,
  font-weight:                        $label-small-font-weight,
  font-size:                          $label-small-font-size,
  line-height:                        $label-small-line-height,
  letter-spacing:                     $label-small-letter-spacing
);

// Body

$body-large-font-family:              $font-family-proxima-nova;
$body-large-font-weight:              $font-weight-proxima-nova-medium;
$body-large-font-size:                .875rem;
$body-large-line-height:              1.125rem;
$body-large-letter-spacing:           0px;

$body-large: (
  font-family:                        $body-large-font-family,
  font-weight:                        $body-large-font-weight,
  font-size:                          $body-large-font-size,
  line-height:                        $body-large-line-height,
  letter-spacing:                     $body-large-letter-spacing
);

$body-medium-font-family:             $font-family-proxima-nova;
$body-medium-font-weight:             $font-weight-proxima-nova-regular;
$body-medium-font-size:               .75rem;
$body-medium-line-height:             1rem;
$body-medium-letter-spacing:          0px;

$body-medium: (
  font-family:                        $body-medium-font-family,
  font-weight:                        $body-medium-font-weight,
  font-size:                          $body-medium-font-size,
  line-height:                        $body-medium-line-height,
  letter-spacing:                     $body-medium-letter-spacing
);

$body-small-font-family:              $font-family-proxima-nova;
$body-small-font-weight:              $font-weight-proxima-nova-semibold;
$body-small-font-size:                .5rem;
$body-small-line-height:              .75rem;
$body-small-letter-spacing:           0px;

$body-small: (
  font-family:                        $body-small-font-family,
  font-weight:                        $body-small-font-weight,
  font-size:                          $body-small-font-size,
  line-height:                        $body-small-line-height,
  letter-spacing:                     $body-small-letter-spacing
);



// ############################################################################
// Colors
// ############################################################################

// Palette

$palette-blue-50:           #f3f6fe;
$palette-blue-100:          #ebf1fe;
$palette-blue-200:          #d3e0fd;
$palette-blue-300:          #acc4fc;
$palette-blue-400:          #84a9fa;
$palette-blue-500:          #6895fa;
$palette-blue-600:          #6492f9;
$palette-blue-700:          #457cf8;
$palette-blue-800:          #0746d5;
$palette-blue-900:          #0539ad;
$palette-blue-1000:         #042c86;

$palette-black-50:          #f8f8f9;
$palette-black-100:         #f4f4f5;
$palette-black-200:         #f0f0f1;
$palette-black-300:         #e7e8e9;
$palette-black-400:         #dadcdd;
$palette-black-500:         #c5c8ca;
$palette-black-600:         #afb4b7;
$palette-black-700:         #9aa0a3;
$palette-black-800:         #858b90;
$palette-black-900:         #5d6266;
$palette-black-1000:        #333638;

$palette-purple-50:         #fbfbff;
$palette-purple-100:        #f7f7fc;
$palette-purple-200:        #d8d8f0;
$palette-purple-300:        #babae5;
$palette-purple-400:        #9d9dda;
$palette-purple-500:        #7f7fce;
$palette-purple-600:        #6161c3;
$palette-purple-700:        #4545b7;
$palette-purple-800:        #3a3a9a;
$palette-purple-900:        #2e2e7a;
$palette-purple-1000:       #23235e;

$palette-orange-50:         #fdf3ec;
$palette-orange-100:        #fadbc6;
$palette-orange-200:        #f6c3a3;
$palette-orange-300:        #f3a875;
$palette-orange-400:        #f09456;
$palette-orange-500:        #ed7c30;
$palette-orange-600:        #e16613;
$palette-orange-700:        #bb5510;
$palette-orange-800:        #96440d;
$palette-orange-900:        #703309;
$palette-orange-1000:       #4b2206;

// Color system

$color-primary:             $palette-blue-500;
$color-primary-bg:          $palette-blue-100;
$color-secondary:           $palette-black-1000;

$color-error:               #fd6f6f; // Pall-Alert-Error-Prim
$color-error-bg:            #fffafa; // Pall-Alert-Error-Sec
$color-error-var:           #fee3e3; // Pall-Alert-Error-Tret
$color-warning:             #d16e34; // Pall-Alert-Warn-Prim
$color-warning-bg:          #fffcfa; // Pall-Alert-Warning-Sec
$color-success:             #25d040; // Pall-Alert-Success-Prim
$color-success-bg:          #f8fef5; // Pall-Alert-Success-Sec
$color-info:                #67a6e4; // Pall-Alert-Info-Prim
$color-info-bg:             #f5fafe; // Pall-Alert-Info-Sec

$color-shadow:              rgba(#35348e, .1);

$neutral-bg:                linear-gradient(168deg, #fafcfe 0%, #fafcff 100%);
$neutral-on-bg:             $palette-black-1000;

$neutral-surface:           #fefefe;
$neutral-surface-bg:        $palette-black-1000;
$neutral-surface-var:       $palette-purple-50;
$neutral-surface-var-alt:   $palette-purple-100;
$neutral-surface-var-bg:    #aaa8bd;
$container-surface:         #f8f8fc;
$container-surface-var:     #f5f8ff;

// Status

$color-status-hue-step:     32;
$color-status-saturation:   65%;
$color-status-lightness:    50%;

$color-status-red:          hsl($color-status-hue-step * 0, $color-status-saturation, $color-status-lightness);
$color-status-brown:        hsl($color-status-hue-step * 1, $color-status-saturation, $color-status-lightness);
$color-status-yellow:       hsl($color-status-hue-step * 2, $color-status-saturation, $color-status-lightness);
$color-status-green1:       hsl($color-status-hue-step * 3, $color-status-saturation, $color-status-lightness);
$color-status-green2:       hsl($color-status-hue-step * 4, $color-status-saturation, $color-status-lightness);
$color-status-green3:       hsl($color-status-hue-step * 5, $color-status-saturation, $color-status-lightness);
$color-status-blue1:        hsl($color-status-hue-step * 6, $color-status-saturation, $color-status-lightness);
$color-status-blue2:        hsl($color-status-hue-step * 7, $color-status-saturation, $color-status-lightness);
$color-status-blue3:        hsl($color-status-hue-step * 8, $color-status-saturation, $color-status-lightness);
$color-status-purple:       hsl($color-status-hue-step * 9, $color-status-saturation, $color-status-lightness);
$color-status-pink:         hsl($color-status-hue-step * 10, $color-status-saturation, $color-status-lightness);
$color-status-grey:         $palette-black-700;
$color-status-black:        $palette-black-1000;
$color-status-draft:        $palette-blue-500;
$color-status-paid:         #5bdc70;
$color-status-partial:      #e6c151;
$color-status-overdue:      #e6516a;
$color-status-done:         $color-success;

$status-colors: (
    "red":      $color-status-red,
    "brown":    $color-status-brown,
    "yellow":   $color-status-yellow,
    "green1":   $color-status-green1,
    "green2":   $color-status-green2,
    "green3":   $color-status-green3,
    "blue1":    $color-status-blue1,
    "blue2":    $color-status-blue2,
    "blue3":    $color-status-blue3,
    "purple":   $color-status-purple,
    "pink":     $color-status-pink,
    "grey":     $color-status-grey,
    "black":    $color-status-black,
    "draft":    $color-status-draft,
    "paid":     $color-status-paid,
    "partial":  $color-status-partial,
    "overdue":  $color-status-overdue,
    "done":     $color-status-done
);



// ############################################################################
// Icons
// ############################################################################

$icon-size-lg:    1.5rem;
$icon-size-md:    1.125rem;
$icon-size-sm:    .875rem;
$icon-size-xs:    .75rem;



// ############################################################################
// Elevations
// ############################################################################

$elevation-600:           0 .5rem 14px $color-shadow;

$elevation-200-top:       0 -.25rem 5px $color-shadow;
$elevation-200-left:      -.25rem 0 5px $color-shadow;
$elevation-200-right:     .25rem 0 5px $color-shadow;
$elevation-200-bottom:    0 .25rem 5px $color-shadow;



// ############################################################################
// Borders
// ############################################################################

$border-radius-small:  .25rem;
$border-radius-medium: .375rem;
$border-radius-large:  .5rem;
