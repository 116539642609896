:root {
  --rpvue-ActivityStreamEmailAttachments-padding: var(--rpvue-spacer-6);
  --rpvue-ActivityStreamEmailAttachments-gap: var(--rpvue-spacer-4);
}

.rpvue-ActivityStreamEmailAttachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailAttachments-padding);
  gap: var(--rpvue-ActivityStreamEmailAttachments-gap);
}
