:root {
  --rpvue-ActivityStream-border:                  var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
  --rpvue-ActivityStream-borderRadius:            var(--rpvue-borderRadiusLg);

  --rpvue-ActivityStream-header-background:       var(--rpvue-colorpalettePurple-100);
  --rpvue-ActivityStream-header-borderBottom:     var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-ActivityStream-list-background:         var(--rpvue-colorNeutralSurface);
}

.rpvue-ActivityStreamContainer {
  display: flex;
  flex-direction: column;
}

.rpvue-ActivityStream {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border: var(--rpvue-ActivityStream-border);
  border-radius: var(--rpvue-ActivityStream-borderRadius);
}

.rpvue-ActivityStream-header {
  background: var(--rpvue-ActivityStream-header-background);
  border-bottom: var(--rpvue-ActivityStream-header-borderBottom);
}

.rpvue-ActivityStream-list {
  display: flex;
  flex-direction: column;

  background: var(--rpvue-ActivityStream-list-background);
}
