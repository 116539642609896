@import "Avatar/Avatar";
@import "Badge/Badge";
@import "Customer";
@import "QuadroContainer/QuadroContainer";
@import "UserMenu";
@import "select";

.rp-vue-app-container {
  flex: 1 1 auto;
  min-width: 0;
}
